<template>
  <div class="tournamentListElement" @click="$router.push('/admin/events/'+tournament.event_id+'/tournament/'+tournament.tournament_id)">
    <div class="tournamentInfo">
      <div class="teamSize">
        {{ tournament.team_size }}
        <span class="material-icons">
          person
        </span>
      </div>
      <div class="detail">
        <h3>
          {{tournament.title}}
        </h3>
        <div>
          <p>
            Teams: {{tournament.teams.length}}
          </p>
          <div class="dot">

          </div>
          <p>
            Start:
            {{startDate}}
          </p>
          <div class="dot">

          </div>
          <p>
            {{tournament.is_registration_open?'Offen':'Geschlossen'}}
          </p>
          <div class="dot">

          </div>
          <p>
            {{'Phase: '+tournament.current_registration_phase}}
          </p>
          <div class="dot" v-if="tournament.max_teams">

          </div>
          <p v-if="tournament.max_teams">
            {{'Team Limit: '+tournament.max_teams}}
          </p>
        </div>
      </div>
    </div>
    <div class="actions">
      <IconButton :button-function="()=>$router.push(tournament.event_id+'/tournament/'+tournament.tournament_id+'/update')" button-type="fluid">
        <span class="material-icons">
        edit
      </span>
      </IconButton>
    </div>


  </div>
</template>

<script>
import {formatDateTime} from "@/helpers";
import IconButton from "@/components/Buttons/IconButton";

export default {
  name: "TournamentListElement",
  components: {IconButton},
  props:{
    tournament:Object,
    toggleTournamentRegistration:Function
  },
  computed:{
    startDate(){
      return formatDateTime(this.tournament.datetime)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/themes";
.dot {
  height: 6px;
  width: 6px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 8px;
  @include theme {
    background-color: theme-get('primary-color')
  }
}
  .tournamentListElement{
    padding:32px;
    transition: 0.25s all;
    border-radius: 16px;
    display: flex;
    width: 100%;

    justify-content: space-between;
    flex-direction: row;
    outline: 2px solid transparent;
    align-items: center;
    @include theme{
      background: theme-get(background-color-1);
      box-shadow: theme-get(shadow-1);
    }
    &:hover{
      /* background-color:red;*/
      @include theme{
        outline-color: theme-get(primary-color);
      }
      transform: scale(1.01);
      cursor: pointer;
    }
    .actions{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap:12px;
    }
    .tournamentInfo{
      display: flex;
      justify-content: center;
      align-items: center;
      gap:16px;
      .teamSize{
        font-size:1.5em;
        font-weight: 500;
        max-width: 100%;
        width: 72px;
        height: 72px;
        border-radius: 144px;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
          font-size: 28px;
        }
        @include theme{
          background: theme-get(primary-background);
          color: theme-get(primary-color);
        }
      }
    }
    .detail{
      display: flex;
      flex-direction: column;
      gap:8px;
      align-items: flex-start;
      div{
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }

    h3{
      font-size: 2.25em;
      font-weight: 600;
      line-height: 1;
      margin: 0;
      @include theme{
        color:theme-get(header-3)
      }
    }
    p{
      line-height: 1;
      margin:0;
      font-size: 1.125em;
      @include theme{
        color:theme-get(text-2)
      }
    }
  }
@media screen and (max-width: 768px){
  .tournamentListElement{
    .tournamentInfo{
      flex-direction: column;
    }
    gap:16px;
    flex-direction: column;
    .detail{
      align-items: center;
      text-align: center;
      div{
        flex-direction: column;
        gap:8px;
      }
      .dot{
        display:none;
      }
    }
  }
}
@media screen and (max-width: 512px){
  .tournamentListElement{

    h3{
      font-size: 2em;
    }
  }
}


</style>
