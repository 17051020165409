<template>
  <div class="eventDetail">
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>
    <div class="eventPreHeader">
      <IconButton button-type="fluid" :button-function="()=>{$router.back()}">
      <span class="material-icons">
        chevron_left
      </span>
      </IconButton>
      <p>
        Event
      </p>
    </div>

       <h1>{{ event.event_name }}</h1>
    <PlainButton :button-function="()=> $router.push($route.params.event_id+'/addTournament')" :is-slim="true" :scale-by-child="true" :is-small="true" button-type="success">
      <div style="display: flex;justify-content: center; align-items: center; line-height: 1; gap:4px">
        <span class="material-icons">add</span>
        Turnier hinzufügen
      </div>
    </PlainButton>
    <div id="tournaments">
      <div class="tournamentList">
        <TournamentListElement v-for="tournament in event.tournaments" :key="tournament.game.game_id"
                               :tournament="tournament" :toggle-tournament-registration="openTournament">
        </TournamentListElement>
      </div>
    </div>
    <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
    <SuccessPop :error-text="alertMessage" v-if="alertType==='alert-success'"></SuccessPop>
  </div>
</template>

<script>
import {adminService} from "@/services";
import TournamentListElement from "@/components/List/Tournament/TournamentListElement";
import PlainButton from "@/components/Buttons/PlainButton";
import {mapActions, mapState} from "vuex";
import ErrorPop from "@/components/Popups/ErrorPop";
import SuccessPop from "@/components/Popups/SuccessPop";
import IconButton from "@/components/Buttons/IconButton";
import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";

export default {
  name: "AdminEventDetail",
  components: {IconButton, TournamentListElement,PlainButton,ErrorPop,SuccessPop,FullscreenLoading},
  data() {
    return {
      selectedGame: 0,
      date: "",
      time: "",
      games: [],
      prize: "",
      event: {},
      ingame_name_source: '',
    }
  },
  created() {
    this.fetchGames();
    this.fetchEvent()
  },
  computed: {
    ...mapState('alert', {
      alertMessage: 'message',
      alertType: 'type'
    }),
    dateTime() {

      let dateString = this.date + "T" + this.time + ":00Z"

      return new Date(dateString);
    }
  },
  methods: {
    ...mapActions("alert", ['success', "error"]),
    createNewTournament() {

      adminService.addTournament({
        'game_id': this.selectedGame,
        'event_id': this.event.event_id,
        'prize': this.prize.length > 0 ? this.prize : null,
        'datetime': this.dateTime.toISOString(),
        'ingame_name_source': this.ingame_name_source,
      }).then(
          event => {
            this.success('Game Added: ' + event.event_name);
            this.fetchEvent()
          },
          error => {
            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    },
    fetchGames() {
      adminService.fetchGames().then(games => {
        this.games = games
      })
    },
    openTournament(id, isOpen){
      adminService.toggleTournament(id,isOpen).then(
          tournamentStatus => {
            this.success('Tournament Status Changed: ' + tournamentStatus.is_registration_open?'Open':'Closed');
            this.fetchEvent()
          },
          error => {

            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    },
    fetchEvent() {
      adminService.fetchEventById(this.$route.params.event_id).then(kgEvent => {
        this.event=kgEvent;
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/themes";

.eventDetail {
  padding: 64px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  .eventPreHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px;
    p{
      font-size: 1.5em;
      margin:0;
      @include theme {
        color: theme-get(text-1)
      }
    }
  }
  h1 {
    font-weight: 600;
    line-height: 1;
    font-size: 4em;
    margin: 16px 0  32px 0;
    @include theme {
      color: theme-get(header-2)
    }
  }
  #tournaments{
    width: 100%;
  }
  .tournamentList {
    padding-top:64px;
    display: flex;

    flex-direction: column;
    gap: 24px;
  }
  #tournamentAdd {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 16px;
    margin-bottom: 64px;
  }
  label {
    @include theme {
      color: theme-get('text-1')
    }
  }
  .textField {
    input {
      width: 200px;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 768px) {
  .eventDetail{
    text-align: center;
    align-items: center;
  }
}
@media screen and (max-width: 512px) {
  .eventDetail{
    padding: 32px;
    h1{
      font-size: 3em;
    }
  }
}
</style>
